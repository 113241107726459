<template>
	<div class="mysection">
		<div class="mysection-header" :class="headerClass">{{ title }}</div>

		<div class="mysection-content">
			<slot/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_vars.scss';

.mysection {
	width: 100%;
	background: #fff;
	border-radius: 6px;
	border: 1px solid rgba(24, 28, 33, 0.06);

	&-header {
		font-weight: bold;
	}
}

.header-extend {
	border-bottom: $commonBorder;
	padding: 10px 20px;
}

@media screen and (min-width: $pcSizeWidth) {
}
</style>

<script>
export default {
	name: 'MySectionComponent',
	props: {
		title: {
			type: String,
			default: ''
		},
	},
	computed: {
		headerClass: function() {
			return this.title !== '' ? 'header-extend' : '';
		},
	},
	methods: {
	},
	mounted () {
	}
}
</script>
